import Vue from 'vue'
import store from '@/store'
import axios from '@/plugins/axiosV2'
import utils from '@/helpers/utilidades'

const http = axios(process.env.VUE_APP_API_VALIJA);

class Valija_solicitudes {
  obtener_solicitudes(options) {
    return http.get(utils.url_options(`/solicitudes`,options));
  }

  obtener_solicitud(id) {
    return http.get(`/solicitudes/${id}`);
  }

  obtener_documento_versiones(id, documento, tipos) {
    return http.get(`/solicitudes/${id}/documentos/${documento}/versiones?validos=${tipos}`);
  }

  subir_documentos(payload) {
    return http.post(`/solicitudes/documentos`, payload);
  }

  descargar_documento(id, documento) {
    return http.get(`/solicitudes/${id}/documentos/${documento}`, {responseType: 'blob'});
  }

  desbloquear(id) {
    return http.put(`/solicitudes/${id}/desbloquear`);
  }

  bloquear(id) {
    return http.put(`/solicitudes/${id}/bloquear`);
  }

  documento_desbloquear(id,documento) {
    return http.put(`/solicitudes/${id}/documentos/${documento}/desbloquear`);
  }

  documento_bloquear(id,documento) {
    return http.put(`/solicitudes/${id}/documentos/${documento}/bloquear`);
  }
}

export default new Valija_solicitudes();