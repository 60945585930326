<template>
  <div>
    <Modal @close="cerrar_modal">
      <div class="title">Cargar documento</div>
      <div class="body">
        <form @submit.prevent="agregar_documento">
          <div class="row form-group">
            <label for="tipo_documento" class="col-form-label col-sm-3">Tipo documento</label>
            <div class="col-sm-9">
              <select v-model="documento.tipo" name="tipo_documento" id="tipo_documento" class="form-control">
                <option v-for="opcion in catalogo.opciones" :key="opcion.id" :value="opcion.valor">{{ opcion.nombre }}</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-3">Archivo</label>
            <div class="col-sm-9">
              <div class="input-group mb-3">
                <div class="form-control" id="nombre_archivo">{{ documento.archivo.name }}</div>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" type="button" @click="seleccionar_documento">Seleccionar documento</button>
                </div>
              </div>
              <input class="input_file" type="file" name="seleccionar_documento" id="seleccionar_documento" ref="seleccionar_documento" @change="agregar_documento_seleccionado">
            </div>
          </div>
          <div class="row form-group">
            <div class="col-sm-12"><button class="btn btn-secondary float-right">Agregar documento</button></div>
          </div>
        </form>

        <table class="lista_documentos">
          <tr>
            <th>Archivo</th>
            <th>Tipo</th>
            <th>Opciones</th>
          </tr>
          <tr v-for="(archivos, index) in documentos" :key="index">
            <td>{{ archivos.archivo.name }}</td>
            <td>{{ buscar_nombre(archivos.tipo) }}</td>
            <td>
              <img class="quitar_etapa" src="https://static.bienparabien.com/constelacion/img/bin.png" alt="Elimitar valor" title="Elimitar documento" @click="quitar_documento(index)">
            </td>
          </tr>
        </table>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-12">
            <button class="btn btn-danger float-right" @click="cerrar_modal">Cancelar</button>
            <button class="btn btn-primary float-right mr-2" @click="subir_documentos">Subir documentos</button>
          </div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/valija/api/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      catalogo: {
        type: Object
        ,default: function() {
          return {
            nombre: null
            ,opciones: []
          }
        }
      }
      ,solicitud_id: null
    }
    ,data: function() {
      return {
        documento: {
          tipo: null
          ,archivo: {
            name: null
          }
        }
        ,documentos: []
      }
    }
    ,methods: {
      seleccionar_documento: function() {
        this.$refs.seleccionar_documento.click();
      }
      ,cerrar_modal: function() {
        this.$emit('close');
      }
      ,agregar_documento_seleccionado: function() {
        if (this.$refs.seleccionar_documento.files.length == 0)
          return this.$helper.showMessage('Error','Debes seleccionar un documento','error','alert');

        this.documento.archivo = this.$refs.seleccionar_documento.files[0];
      }
      ,agregar_documento: function() {
        if (!this.documento.tipo)
          return this.$helper.showMessage('Error','Debes seleccionar el tipo','error','alert');


        if (!this.documento.archivo.name)
          return this.$helper.showMessage('Error','Debes seleccionar el documento','error','alert');

        this.documentos.push(this.documento);

        this.documento = {
          tipo: null
          ,archivo: {
            name: null
          }
        }

        this.$refs.seleccionar_documento.value = null;
      }
      ,quitar_documento: function(index) {
        this.$log.info('index',index);
        let documentos = []
        for(let i=0;i<this.documentos.length; i++) {
          if (i!=index)
            documentos.push(this.documentos[i]);
        }

        this.documentos = documentos;
      }
      ,buscar_nombre: function(valor) {
        let nombre = null;
        let opciones = this.catalogo.opciones;

        for(let i=0; i<opciones.length; i++) {
          if (opciones[i].valor == valor) {
            nombre = opciones[i].nombre;
            i=opciones.length;
          }
        }

        return nombre;
      }
      ,subir_documentos: async function() {
        try {
          if (this.documentos.length == 0)
            return this.$helper.showMessage('Error','Debes agregar al menos un documento','error','alert');

          let formData = new FormData();
          formData.append('solicitud_id',this.solicitud_id);

          let numDoc = 0;
          this.documentos.forEach(documento => {
            formData.append('archivos['+numDoc+'][tipo]',documento.tipo);
            formData.append('archivos['+numDoc+'][archivo]',documento.archivo);
            numDoc++;
          });

          let res = (await api.subir_documentos(formData)).data;
          this.$log.info('res',res);

          this.$emit('update');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
    }
  }
</script>

<style lang="scss">
  .input_file {
    display: none;
  }

  #nombre_archivo {
    overflow: hidden;
  }

  .lista_documentos {
    width: 100%;

    tr {
      th {
        background-color: #333333;
        color: #fff;
        padding: 10px 5px;
      }

      th:nth-child(1) {
        width: 40%
      }

      th:nth-child(2) {
        width: 40%
      }

      td {
        padding: 10px 5px;

        img {
          width: 15px;
        }
      }
    }
  }
</style>